#london-map,
#lagos-map {
  display: none;
}

#london-map.active {
  display: block;
}

#lagos-map.active {
  display: block;
}

.tab {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.tab.active {
  color: #b3d5fc;
}

iframe {
  display: none;
}

iframe.active {
  display: block;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
}

.card {
  width: 15%;
  height: 350px;
  background-color: #3498db;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  opacity: 0.9;
  transition: transform 1.3s ease, opacity 0.3s ease;
}

/* .card-container:hover .card:nth-child(1) {
  transform: rotate(-12deg) translateX(-40vw);
}

.card-container:hover .card:nth-child(2) {
  transform: rotate(-9deg) translateX(-20vw);
}

.card-container:hover .card:nth-child(3) {
  transform: rotate(0deg) translateX(0) translateY(0);
}

.card-container:hover .card:nth-child(4) {
  transform: rotate(9deg) translateX(20vw);
}

.card-container:hover .card:nth-child(5) {
  transform: rotate(12deg) translateX(40vw);
} */

@keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg); /* Resting phase */
  }
  100% {
    transform: rotate(360deg); /* Spinning fast */
  }
}

.custom-spin {
  animation: custom-spin 3s cubic-bezier(0.6, 0.05, 0.28, 0.91) infinite;
}

@keyframes bg-change {
  0% {
    background: linear-gradient(135deg, #020015, #050112);
  }
  25% {
    background: linear-gradient(135deg, #050112, #b3d5fc);
  }
  50% {
    background: linear-gradient(135deg, #b3d5fc, #e8998d);
  }
  75% {
    background: linear-gradient(135deg, #e8998d, #db5764);
  }
  100% {
    background: linear-gradient(135deg, #db5764, #020015);
  }
}

.animate-bg-change {
  animation: bg-change 12s infinite;
  background-size: 300% 300%; /* Smooth gradient transitions */
  transition: bg-change 12s ease-in-out infinite;
}
