#london-map, #lagos-map {
  display: none;
}

#london-map.active, #lagos-map.active {
  display: block;
}

.tab {
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}

.tab.active {
  color: #b3d5fc;
}

iframe {
  display: none;
}

iframe.active {
  display: block;
}

.card-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
}

.card {
  color: #fff;
  opacity: .9;
  background-color: #3498db;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 350px;
  transition: transform 1.3s, opacity .3s;
  display: flex;
  position: absolute;
}

@keyframes custom-spin {
  0% {
    transform: rotate(0);
  }

  80% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-spin {
  animation: 3s cubic-bezier(.6, .05, .28, .91) infinite custom-spin;
}

@keyframes bg-change {
  0% {
    background: linear-gradient(135deg, #020015, #050112);
  }

  25% {
    background: linear-gradient(135deg, #050112, #b3d5fc);
  }

  50% {
    background: linear-gradient(135deg, #b3d5fc, #e8998d);
  }

  75% {
    background: linear-gradient(135deg, #e8998d, #db5764);
  }

  100% {
    background: linear-gradient(135deg, #db5764, #020015);
  }
}

.animate-bg-change {
  transition: bg-change 12s ease-in-out infinite;
  background-size: 300% 300%;
  animation: 12s infinite bg-change;
}
/*# sourceMappingURL=index.14c6b750.css.map */
